<template>
    <div style="width: fit-content; background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <div style="padding:1rem">
                    <h2 class="pb-5" style="font-weight: 600">Calculator</h2>
                    <h4 class="pb-3">In this section you can explore how a trade performed statistically to help you determine its risk and reward.This program creates a table showing the results of putting on the same trade every year at the same time.</h4>
                    <h3 class="pt-5">Table</h3>
                    <h4><b>Open, Close: </b> In any given year, if the selected open date is not traded, the program moves forward in time up to four days looking for an open. If the close date is not traded, the program moves backward in time up to four days looking for a close.</h4>
                    <h4><b>Change: </b>difference between the open value and the close value.</h4>
                    <h4><b>Equity Change: </b>difference in dollar value between the open date and the close date (equity change = change x unit move)</h4>
                    <h4><b>Days: </b>difference in days between the open date and the close date.</h4>
                    <h4><b>Average profit per day: </b>equity change / days</h4>
                    <h4><b>Maximum adverse excursion: </b>date and value of the greatest loss that occurred between the during the trade.</h4>
                    <h4><b>Maximum profitable excursion: </b>date and value of the greatest profit that occurred between the during the trade.</h4>
                    <hr>
                    <h4><b>In the last row of the table we find:</b></h4>
                    <ul>
                        <li><h4> - Number of years examined,</h4></li>
                        <li><h4> - Percent profitable: ratio of the number of profitable trades to the total number of trades,</h4></li>
                        <li><h4> - Averages of the columns</h4></li>
                    </ul>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>
        <table v-if="namespace !== 'undefined'" style="text-align: left; margin: 2px 3px 1px 0px;"> 
            <tr>
                <td style="vertical-align: bottom;">
                    <table>
                        <tr>                          
                              <td style="vertical-align: bottom; padding: 1px 0 0 0"> 
                        <commodity-box v-bind:namespace="namespace">
                            <template v-slot:contract-aligner>
                                <contract-aligner-selector v-bind:namespace="namespace" v-bind:title="'Aligner'"></contract-aligner-selector>     
                            </template>                  
                        </commodity-box>
                                </td>
                             </tr>
        </table>
        </td>
        <td style="vertical-align: bottom; text-align: right; height: available; padding: 1px; background-color: white;">

            <table style="background-color: white; height: 100%; background-color: white;">
                <tr>
         <td style="vertical-align: bottom;">
                             <trade-specs v-bind:namespace="namespace" style="margin: 0 5px -1px 7px"/>
                            </td> 
                            <td style="vertical-align: bottom; background-color: white">
        <b-row align-h="start" align-v="start" class="row no-gutters" style=" background-color: white;">
            <b-col md="auto">  
                <b-button-group style="border: 1px solid gray; padding: 1px; margin: 2px 0 -1px 0px; white-space: nowrap;
                                         background-color: #E8E8E8; border-radius: 3px;"> 
                                               <add-to-portfolio-button v-bind:namespace="namespace" style="margin: 1px 0 0 0"></add-to-portfolio-button>                            
                    <b-button v-b-toggle.sidebar-help variant="warning" size="sm" style="border: 1px solid darkgray; margin: 1px;">Help</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        </td>
        
                         
        </tr>
        </table>

        </td>
        </tr>
        </table>                   
    </div>
</template>

<script>

    import commodityBox from '@/components/commodity-box';
    import contractAlignerSelector from '@/components/contract-aligner-selector';
    import addToPortfolioButton from '@/components/add-to-portfolio-button';
    import tradeSpecs from '@/components/trade-specs';

    export default {
        components: {
            commodityBox,
            contractAlignerSelector,
            addToPortfolioButton,
            tradeSpecs
        },
        mounted() {
            console.log("calculator-input.vue mounted() starting. this.namespace=", this.namespace);
        },
        props: ['namespace']
    };

</script>
