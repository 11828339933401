<template>
    <div>
        <span v-if="isOpen">
            <b-button v-b-modal.add-to-portfolio-modal size="sm" style="font-weight: bold; border: 1px solid gray; color: white;
                      background-color: #40B5AD">
                save
            </b-button>
        </span>

        <b-modal id="add-to-portfolio-modal" ref="add-to-portfolio-modal" ok-only variant="primary" header-bg-variant="primary"
                 header-text-variant="'light'" size="lg" title="Save">

            <p style="font-size: 14px">Which folder would you like to add this trade to?</p>

            <portfolio-folders v-bind:namespace="namespace"></portfolio-folders>             

            <table>
                <tr>
                    <td>
                <b-button variant="success" v-on:click="createTrade" style="margin: 20px 0 0 0">save</b-button>
                </td>
                <td style="padding: 22px 0 0 15px; font-weight: bold">
                    {{result}}
                </td>
                <td style="padding: 24px 0 0 14px;">
                <b-button v-if="result !== ''"
                          v-b-modal.add-to-portfolio-modal 
                          v-on:click="goToPortfolio"
                          style="font-weight: bold; margin: 0px; border: 1px solid gray;
                          color: white; background-color: blue">
                    Go to the Portfolio.
                </b-button>
                </td>
                </tr>
            </table>
        </b-modal>
    </div>
</template>

<script>

    import * as fb from "@/firebase";
    import {spreadTitle} from "@/js/main";
    import $ from "jquery";
    import portfolioFolders from '@/components/portfolio-folders';
    import { bus } from '@/main';
    import {isSpreadOpen} from "@/js/main";


    export default {
        components: {
            portfolioFolders
        },
        mounted() {
           // console.log("add-to-portfolio-button mounted() starting.");
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                console.log('add-to-portfolio-modal is about to be hidden.', bvEvent, modalId);
                this.result = "";
            });
        },
        beforeDestroy(){
            console.log("add-to-portfolio-button beforeDestroy() starting.");
            this.$root.$off('bv::modal::hide', (bvEvent, modalId) => {
                console.log('add-to-portfolio-modal is about to be hidden.', bvEvent, modalId);
                this.result = "";
            });
        },
        props: ['namespace'],
        data: function () {
            return {
                result: ""
            };
        },
        computed: {
            email() {
                return this.$store.state.user.email;
            },
            open() {
                return this.$store.state[this.namespace].open;
            },
            program() {
                return this.$store.state[this.namespace].program;
            },
            portfolioDirectory: {
                get() {
                    return this.$store.state.user.portfolioDirectory;
                },
                set(portfolioDirectory) {
                    this.$store.commit('setPortfolioDirectory', portfolioDirectory);
                }
            },
            isOpen(){
               // let spread = this.$store.state[this.namespace].selected[0];
               // console.log("spread=", spread);
               // console.log("isSpreadOpen=", isSpreadOpen);
                return isSpreadOpen(this.$store.state[this.namespace].selected[0]);
            }
        },
        methods: {
            goToPortfolio() {
                console.log("goToPortfolio() starting.");
                let moduleArray = this.$store.getters['moduleArray'];
                // console.log("moduleArray=", JSON.parse(JSON.stringify(moduleArray)));

                let portfolioModule = moduleArray.find(x => x.program === "Portfolio");
                console.log("portfolioModule=", portfolioModule);
                if (typeof portfolioModule !== 'undefined') {
                    let portfolioModuleName = portfolioModule.moduleName;
                    // console.log("portfolioModuleName=", portfolioModuleName);

                    let refs = this.$parent.$parent.$parent.$refs;
                    console.log("refs=", refs);

                    let child = refs[portfolioModuleName + "Output"][0];
                    console.log("child=", child);
                    child.activate();
                } else {
                    bus.$emit('newTab', "Portfolio");
                }
            },
            getTicker(form) {
                let invertDatesMultiplier = 1;
                let noLegSwapping = true;
                let includeUnitMoves = true;
                let ticker = spreadTitle(JSON.parse(JSON.stringify(form)), invertDatesMultiplier, noLegSwapping, this.symbols, includeUnitMoves);
                console.log("ticker=", ticker);
                return ticker;
            },
            addToPortfolio(values) {
                console.log("addToPortfolio() starting. values=", values);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                delete generalForm.browserSideOnly;
                delete generalForm.playback;
                delete generalForm.indicators;

                console.log("this.open=", this.open);
                let openBar = values[this.open.replace(/-/g, "")];
                console.log("openBar=", openBar);

                let openPrice = typeof openBar !== 'undefined' ? openBar.close : null;
                console.log("openPrice=", openPrice);
                generalForm.open = this.open;
                generalForm.portfolio.openPrice = openPrice;
                generalForm.portfolio.numberOfPositions = 1;
                generalForm.portfolio.folder = this.portfolioDirectory;
                generalForm.ticker = this.getTicker(generalForm, "old");
               
                console.log("generalForm=", generalForm);
                console.log("this.email=", this.email);

                fb.db.collection("portfolio-alpha")
                        .doc(this.email)
                        .collection("strategies")
                        .add(generalForm)
                        .then((docRef) => {
                            console.log("Document written with ID: ", docRef.id);
                            console.log('chart saved.');
                            this.result = "Success.";
                        });
            },
            createTrade: function () {
                console.log("createTrade() starting.");

                console.log("this.program=", this.program);
                if (this.program === "BasicCharts") {
                    /* console.log("this.$parent.$parent.$parent.$refs=", this.$parent.$parent.$parent.$refs);
                     console.log("this.$parent.$parent.$parent.$refs[this.namespace+ '-basic-chart-output']]=",
                     this.$parent.$parent.$parent.$refs[this.namespace + '-basic-chart-output']);*/

                    let values = JSON.parse(JSON.stringify(this.$parent.$parent.$parent.$refs[this.namespace +
                            '-basic-chart-output'][0]._data.parsedData.values));
                    this.addToPortfolio(values);
                } else if (this.program === "Calculator") {
                    this.getData().then(values => {
                        console.log("values=", values);
                        this.addToPortfolio(values);
                    });
                }


            },
            getData() {
                console.log("getData() starting.");
                return new Promise((resolve) => {

                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    delete generalForm.browserSideOnly;
                    delete generalForm.chartParameters;
                    delete generalForm.playback;
                    generalForm.generator = "BasicBarsGenerator";
                    generalForm.study = "basic";
                    console.log("generalForm=", generalForm);

                    let json = JSON.stringify(generalForm, null, 2);
                    if (generalForm.selected.length > 0) {
                        let that = this;
                        var currentRequest = $.ajax({
                            url: this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/BasicEodBarsControllerServlet/",
                            type: "POST",
                            data: {"json": json},
                            beforeSend: function () {
                                //  console.log("beforeSend starting.");
                                if (currentRequest != null) {
                                    //  console.log("aborting previous request.");
                                    currentRequest.abort();
                                }
                            },
                            success: function (data) {
                                // console.log("data=", data);
                                that.$store.commit('decrementNumberOfAjaxCalls');
                                let values = JSON.parse(data)[0].values;
                                // console.log("values=", values);
                                resolve(values);
                            },
                            fail: function (err) {
                                that.$store.commit('decrementNumberOfAjaxCalls');
                                console.log("ajax call failed. err=", err);
                            }
                        });
                    }
                });
            }
        }
    }
</script>
